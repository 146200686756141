.home-page .links {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.home-page .dropzone-area {
  padding: 1.5rem;
}
.home-page .dropzone-area .inner-dropzone {
  background: linear-gradient(
    180deg,
    rgba(0, 104, 129, 0.2) 0%,
    rgba(67, 186, 148, 2e-5) 136.98%
  );
  padding: 2.5rem;
  border-radius: 10px;
  text-align: center;
}
.home-page .dropzone-area img {
  width: 85px;
}
.home-page .dropzone-area .buttons {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.home-page .template-area {
  padding: 1rem;
}
.home-page .template-area img {
  width: 25px;
}
.home-page .template-area .button {
  width: 30px;
  height: 30px;
  background: linear-gradient(
    180deg,
    #006881 0%,
    rgba(67, 186, 148, 0.0001) 136.98%
  );
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.home-page .progress-area {
  border-radius: 10px;
  box-shadow: 0px 15px 30px rgba(224, 224, 236, 0.4);
  padding: 1rem;
  position: relative;
  overflow: hidden;
}
.home-page .progress-area img {
  width: 50px;
}
.home-page .progress-area .swimming {
  background: linear-gradient(
    180deg,
    #006881 0%,
    rgba(67, 186, 148, 0.0001) 136.98%
  );
  position: absolute;
  left: -6.5rem;
  top: -3rem;
  height: 200px;
  width: 200px;
  opacity: 0.3;
  border-radius: 50%;
}

/* Extra small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .home-page .links {
    padding: 0 2rem;
  }

  .home-page .dropzone-area .buttons {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .home-page .links {
    padding: 0 8rem;
  }

  .home-page .dropzone-area .buttons {
    padding: 0 7rem;
  }

  .home-page .progress-area {
    padding: 1.5rem;
  }
  .home-page .progress-area img {
    width: 75px;
  }
  .home-page .progress-area .swimming {
    left: -4rem;
    top: -2rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .home-page .links {
    padding: 0 12rem;
  }

  .home-page .dropzone-area .buttons {
    padding: 0 11rem;
  }
}
