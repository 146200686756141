.download-area {
  padding: 1rem 1.5rem;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #858585;
}
.download-area .bg {
  position: absolute;
  width: 150px;
  height: 150px;
  background: linear-gradient(
    180deg,
    #006881 0%,
    rgba(67, 186, 148, 0.0001) 136.98%
  );
  left: -60px;
  top: -25px;
  border-radius: 50%;
  opacity: 0.5;
}
