.pagination {
  font-weight: 700;
}
.pagination .page-item.active .page-link {
  background: linear-gradient(
    180deg,
    rgba(0, 104, 129, 1) 0%,
    rgba(67, 186, 148, 1) 120%
  );
}
