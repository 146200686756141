body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.create-gap {
  row-gap: 2rem;
}

.create-small-gap {
  row-gap: 1rem;
}

label {
  margin-bottom: 0.25rem;
}
