.table {
  text-align: center;
  margin-bottom: 0;
  color: #858585;
  font-family: "Poppins", sans-serif;
}

th,
td {
  vertical-align: middle !important;
  font-size: 12px !important;
}

th {
  font-weight: 700 !important;
}

td {
  font-weight: 400 !important;
}

.table .action {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #006881;
}

/* Extra small devices (portrait phones, less than 576px)
  No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
