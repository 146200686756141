.form-card {
  position: relative;
  padding: 2rem;
}

.form-card .oval {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
}

.form-card .oval2 {
  position: absolute;
  left: 40%;
  top: 0;
  width: 50%;
}

/* Extra small devices (portrait phones, less than 576px)
  No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .form-card {
    padding: 4rem;
    width: 500px;
    margin: 0 auto;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
