.notfound-page {
  padding: 2rem 0;
  text-align: center;
}

.notfound-page img.notfound-bg {
  position: absolute;
  left: 0;
  top: 6rem;
  right: 0;
  margin: 0 auto;
  width: 250px;
  z-index: -1;
}

.notfound-page img.notfound-ballons {
  display: none;
}

.notfound-page h1 {
  font-size: 6rem;
  font-weight: bold;
  color: #939393;
  margin-bottom: 0;
}

.notfound-page h2 {
  font-size: 1.5rem;
  font-weight: bolder;
  color: #939393;
}

.notfound-page p {
  font-size: 1rem;
  color: #939393;
  max-width: 30rem;
  margin: 0 auto 2rem;
}

@media (min-width: 768px) {
  .notfound-page {
    padding: 10rem 0;
  }

  .notfound-page img.notfound-bg {
    top: 14rem;
    width: 300px;
  }

  .notfound-page img.notfound-ballons {
    display: block;
    position: absolute;
    left: 6rem;
    width: 200px;
    top: 24rem;
    z-index: -1;
  }
}

@media (min-width: 992px) {
  .notfound-page {
    padding: 20rem 0;
  }

  .notfound-page img.notfound-bg {
    width: 400px;
    top: 22rem;
  }

  .notfound-page img.notfound-ballons {
    width: 300px;
    top: 28rem;
  }
}

@media (min-width: 1200px) {
  .notfound-page {
    padding: 6rem 0;
  }

  .notfound-page img.notfound-bg {
    top: 8rem;
  }

  .notfound-page img.notfound-ballons {
    top: 14rem;
  }
}
