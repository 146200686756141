.subheading {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 20px;
}
.subheading hr {
  border: 1px solid rgba(0, 104, 129, 0.5);
  width: 4rem;
  margin: 0.5rem auto 0;
}
