.user-dropdown {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.user-dropdown .user-icon {
  background: linear-gradient(
    180deg,
    #006881 0%,
    rgba(67, 186, 148, 0.0001) 136.98%
  );
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.user-dropdown .user-name {
  display: none;
}

.user-dropdown .user-menu {
  position: absolute;
  right: 0;
  top: 2rem;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  width: 10rem;
}

.user-dropdown .user-menu div {
  padding: 0.5rem 0.75rem;
}

.user-dropdown .user-menu div p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

/* Extra small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .user-dropdown .user-name {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .user-dropdown .user-menu div:hover {
    background: #006881;
    color: white;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
