.palisade-detail .cards {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #858585;
}

/* .palisade-detail .cert-area:hover {
  text-decoration: underline;
} */

/* Extra small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .palisade-detail .cards .middle-container {
    padding: 1rem 6rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
